import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import Navbar from '../../components/Navbar'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <>
        <Navbar showMenu />
        <Layout>
          <section className="section">
            <div className="container">
              <div className="content">
                <BlogRoll />
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}
